<template>
  <!-- eslint-disable -->
  <div class="main-container">
    <div class="lefty">
      <div   class="image-wrapper">
        <div style="height: 100%; width: 100%; position: absolute; top:0px; left: 0px; opacity: 0.09; border-radius: 15px;" :style="{'background':'linear-gradient(to top, '+prestador.admin.assets.colors.primary+', '+prestador.admin.assets.colors.secondary+')'}"></div>
        <!-- <div style="position: absolute; height: 100%; width: 100%; opacity: 0.4;" :style="{'background-color':prestador.admin.assets.colors.primary}"></div> -->
        <img  class="main-img" :src="'https://assets-prestador-clinicgo.s3.sa-east-1.amazonaws.com/'+prestador.id+'/images/logo.png'" alt="">
      </div>
      
    </div>
    <div class="righty">
      <div class="formy">
        <form v-if="!cargando" action="javascript:void(0);" method="get">

        <img class="minilogo" :src="'https://assets-prestador-clinicgo.s3.sa-east-1.amazonaws.com/'+prestador.id+'/images/logo.png'" alt="">
        <div class="title">¡Bienvenido al panel de <span style="font-weight: 700;" :style="{'color': prestador.admin.assets.colors.primary}">{{ prestador.nombre }}</span>! 👋</div>
        <div class="subtitle">Por favor inicia sesión para comenzar.</div>

        <div style="margin-bottom: 40px;" class="input-f">
          <div class="top-input">Usuario o correo</div>
          <input v-model="login_correo_electronico" type="text" placeholder="Escribe tu usuario o correo" name="" id="">
        </div>

        <div style="margin-bottom: 20px;" class="input-f">
          <div class="top-input">Contraseña</div>
          <div :style="{'color': prestador.admin.assets.colors.primary}" class="forgot">¿Olvidaste tu contraseña?</div>
          <input v-model="login_password" placeholder="*********" type="password" name="" id="">
        </div>
        <div class="opciones">
          <span v-if="error_msg_login != ''" class="errormsg"><i class="tf-icons ti ti-alert-triangle"></i> {{ error_msg_login }}</span>
        </div>

        <button @click="entrar()" :style="{'background': 'linear-gradient(to right, '+prestador.admin.assets.colors.primary+', '+prestador.admin.assets.colors.secondary+')'} " class="btn-ingresar">
          Ingresar
          <div
            v-if="cargando"
            style="display:inline-block;"
          >
            <div style="transform:translate(4px, 0px);">
              <div
                class="spinner-border spinner-border-sm"
                role="status"
              />
            </div>
          </div>
        </button>

      </form>
      <div v-else>
          <Loader :width="100" :height="100" />
      </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';

/* Core CSS */
import '@/assets/estilo_importado/vendor/css/rtl/core.css';
import '@/assets/estilo_importado/vendor/css/rtl/theme-default.css';
import '@/assets/estilo_importado/css/demo.css';

/* Vendor css */
import '@/assets/estilo_importado/vendor/libs/node-waves/node-waves.css';
import '@/assets/estilo_importado/vendor/libs/perfect-scrollbar/perfect-scrollbar.css';
import '@/assets/estilo_importado/vendor/libs/typeahead-js/typeahead.css';

import '@/assets/estilo_importado/vendor/css/pages/page-auth.css';
import '@/assets/estilo_importado/vendor/libs/@form-validation/umd/styles/index.min.css';


/* Icons */
import '@/assets/estilo_importado/vendor/fonts/fontawesome.css';
import '@/assets/estilo_importado/vendor/fonts/tabler-icons.css';
import Loader from '@/components/Loader.vue';

export default {
  name: 'HelloWorld',
  components: {

    Loader,

  },
  data() {
    return {
      login_correo_electronico:'',
      login_password:'',
      cargando:false,
      error_msg_login:'',
    }
  },
  created(){

  },
  computed: {
  ...mapGetters(['admin_datos', 'isLoggedIn', 'prestador']),
  },
  methods:{
    ...mapActions(['loginAdmin', 'getNotificaciones']),
      entrar(){
       this.cargando = true;
       this.loginAdmin({email: this.login_correo_electronico, password:this.login_password, prestador_id:this.prestador.id})
       .then(resp=>{
         this.cargando = false;
         this.$router.push('/Inicio')
         this.getNotificaciones()
       })
       .catch(err=>{
         this.cargando = false;
         this.error_msg_login = err.response.data

       })
     },


  },

}
</script>


<style scoped>
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css);
.errormsg{
  color:#FF4136;
  display: inline-block;
  background-color: #ff403625;
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 14px;
 
  
}
.main-container{
  display: flex;
  background-color: white; 
  height: 100vh;
}
.lefty{
  padding: 40px;
  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: center;

}
.image-wrapper{
  
  height: 100%;
  width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.lefty img{
  max-width: 300px;
  margin: 0 auto;
  
}

.righty{
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.minilogo{

  max-height: 40px;
  position: absolute;
  left:0px;
  top:-60px;
}
.formy{
  position: relative;
  text-align: left;
  max-width: 400px;
}
.title{
  font-size: 30px;
  margin-bottom: 20px;
}
.subtitle{
  color: rgba(0,0,0,0.4);
  margin-bottom: 20px;
}
.top-input{
  font-size: 14px;
  margin-bottom: 6px;
}
.input-f input{
  padding:10px;
  border-radius: 6px;
  width: 100%;
  outline: none;
  border: 1px solid rgba(0,0,0,0.4);

}
.input-f{
  position: relative;
}
.forgot{
  position: absolute;
  right:0px;
  top:0px;
  font-size: 14px;

}
.btn-ingresar{
  outline: none;
  border:none;
  width: 100%;
  color: white;
  height: 50px;
  font-weight: 700;
  border-radius: 8px;
  margin-top:20px;
}
@media only screen and (max-width: 700px) {
  .lefty{
    display: none;
  } 
  .main-container{
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
}


</style>

